define("api/serializers/worker", ["exports", "api/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class WorkerSerializer extends _application.default {
    // =methods

    /**
     * If `adapterOptions.workerGeneratedAuthToken` is set,
     * then the payload is serialized via `serializedWithGeneratedToken`.
     * @override
     * @param {Snapshot} snapshot
     * @return {object}
     */
    serialize(snapshot) {
      let serialized = super.serialize(...arguments);
      const workerGeneratedAuthToken = snapshot?.adapterOptions?.workerGeneratedAuthToken;
      if (workerGeneratedAuthToken) serialized = this.serializeWithGeneratedToken(snapshot, workerGeneratedAuthToken);
      return serialized;
    }
    /**
     * Returns a payload with `worker_generated_auth_token` serialized.
     * @param {Snapshot} snapshot
     * @param {[string]} worker_generated_auth_token
     * @return {object}
     */


    serializeWithGeneratedToken(snapshot, workerGeneratedAuthToken) {
      return {
        scope_id: snapshot.attr('scope').scope_id,
        worker_generated_auth_token: workerGeneratedAuthToken
      };
    }

  }

  _exports.default = WorkerSerializer;
});
define("api/serializers/managed-group", ["exports", "@babel/runtime/helpers/esm/defineProperty", "api/serializers/application", "ember-copy"], function (_exports, _defineProperty2, _application, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ManagedGroupSerializer extends _application.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "serializeScopeID", false);
    }

    serialize() {
      const serialized = super.serialize(...arguments);

      if (serialized.attributes.filter_string !== undefined) {
        serialized.attributes.filter = serialized.attributes.filter_string;
      }

      delete serialized.attributes.filter_string;
      return serialized;
    }

    normalize(typeClass, hash) {
      const normalizedHash = (0, _emberCopy.copy)(hash, true);

      if (normalizedHash?.attributes?.filter) {
        normalizedHash.attributes.filter_string = normalizedHash.attributes.filter;
        delete normalizedHash.attributes.filter;
      }

      for (var _len = arguments.length, rest = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        rest[_key - 2] = arguments[_key];
      }

      return super.normalize(typeClass, normalizedHash, ...rest);
    }

  }

  _exports.default = ManagedGroupSerializer;
});
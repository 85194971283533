define("api/models/credential-library", ["exports", "api/generated/models/credential-library"], function (_exports, _credentialLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TYPES_CREDENTIAL_LIBRARY = _exports.TYPE_CREDENTIAL_LIBRARY_VAULT_SSH_CERTIFICATE = _exports.TYPE_CREDENTIAL_LIBRARY_VAULT_GENERIC = _exports.options = void 0;

  /**
   * Enum options for credential library.
   */
  const options = {
    http_method: ['GET', 'POST'],
    key_type: ['ed25519', 'ecdsa', 'rsa']
  };
  _exports.options = options;
  const TYPE_CREDENTIAL_LIBRARY_VAULT_GENERIC = 'vault-generic';
  _exports.TYPE_CREDENTIAL_LIBRARY_VAULT_GENERIC = TYPE_CREDENTIAL_LIBRARY_VAULT_GENERIC;
  const TYPE_CREDENTIAL_LIBRARY_VAULT_SSH_CERTIFICATE = 'vault-ssh-certificate';
  /**
   * Supported Credential Library types.
   */

  _exports.TYPE_CREDENTIAL_LIBRARY_VAULT_SSH_CERTIFICATE = TYPE_CREDENTIAL_LIBRARY_VAULT_SSH_CERTIFICATE;
  const TYPES_CREDENTIAL_LIBRARY = Object.freeze([TYPE_CREDENTIAL_LIBRARY_VAULT_GENERIC, TYPE_CREDENTIAL_LIBRARY_VAULT_SSH_CERTIFICATE]);
  _exports.TYPES_CREDENTIAL_LIBRARY = TYPES_CREDENTIAL_LIBRARY;

  class CredentialLibraryModel extends _credentialLibrary.default {
    // =attributes

    /**
     * True if credential is an unknown type.
     * @type {boolean}
     */
    get isUnknown() {
      return !TYPES_CREDENTIAL_LIBRARY.includes(this.type);
    }
    /**
     * True if credential is a vault type.
     * @type {boolean}
     */


    get isVault() {
      return this.type === TYPE_CREDENTIAL_LIBRARY_VAULT_GENERIC || this.type === TYPE_CREDENTIAL_LIBRARY_VAULT_SSH_CERTIFICATE;
    }
    /**
     * True if credential is a generic vault type.
     * @type {boolean}
     */


    get isVaultGeneric() {
      return this.type === TYPE_CREDENTIAL_LIBRARY_VAULT_GENERIC;
    }
    /**
     * True if credential is a vault ssh cert type.
     * @type {boolean}
     */


    get isVaultSSHCertificate() {
      return this.type === TYPE_CREDENTIAL_LIBRARY_VAULT_SSH_CERTIFICATE;
    }

  }

  _exports.default = CredentialLibraryModel;
});
define("api/serializers/auth-method", ["exports", "api/serializers/application", "ember-copy"], function (_exports, _application, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AuthMethodSerializer extends _application.default {
    // =methods

    /**
     * Delegates to a type-specific serialization, or default.
     * @override
     * @param {Snapshot} snapshot
     * @return {object}
     */
    serialize(snapshot) {
      switch (snapshot.record.type) {
        case 'oidc':
          return this.serializeOIDC(...arguments);

        default:
          return this.serializeDefault(...arguments);
      }
    }
    /**
     * Default serialization omits `attributes`.
     * @return {object}
     */


    serializeDefault() {
      let serialized = super.serialize(...arguments);
      delete serialized.attributes;
      return serialized;
    }
    /**
     * If `adapterOptions.state` is set, the serialization should
     * include **only state** and version.  Normally, this is not serialized.
     * @param {Snapshot} snapshot
     * @return {object}
     */


    serializeOIDC(snapshot) {
      let serialized = super.serialize(...arguments);
      const state = snapshot?.adapterOptions?.state;

      if (state) {
        serialized = this.serializeOIDCWithState(snapshot, state);
      } else {
        delete serialized.attributes.state;
      }

      return serialized;
    }
    /**
     * Returns a payload containing only state and version.
     * @param {Snapshot} snapshot
     * @param {string} state
     * @return {object}
     */


    serializeOIDCWithState(snapshot, state) {
      return {
        version: snapshot.attr('version'),
        attributes: {
          state
        }
      };
    }
    /**
     * Sorts the array in order of "is_primary" such that the primary method
     * appears first.  The "natural" order of auth methods places primary first.
     *
     * @override
     * @return {object}
     */


    normalizeArrayResponse() {
      const normalized = super.normalizeArrayResponse(...arguments);
      normalized.data = normalized.data.sort(a => a.attributes.is_primary ? -1 : 1);
      return normalized;
    }
    /**
     * Sets `is_primary` to `false` if it is missing in the response.  The API
     * omits "zero" values per:
     * https://developers.google.com/protocol-buffers/docs/proto3#default
     *
     * TODO:  generalize this so that all zero values are reified.
     * @override
     * @param {Model} typeClass
     * @param {Object} hash
     * @return {Object}
     */


    normalize(typeClass, hash) {
      let normalizedHash = (0, _emberCopy.copy)(hash, true); // switch (normalizedHash.type) {
      //   case 'oidc':
      //     normalizedHash = this.normalizeOIDC(normalizedHash);
      //     break;
      // }

      for (var _len = arguments.length, rest = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        rest[_key - 2] = arguments[_key];
      }

      const normalized = super.normalize(typeClass, normalizedHash, ...rest);

      if (!normalized.data.attributes.is_primary) {
        normalized.data.attributes.is_primary = false;
      }

      return normalized;
    }

  }

  _exports.default = AuthMethodSerializer;
});
define("api/transforms/string-array", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class StringArrayTransform extends _transform.default {
    deserialize(serialized) {
      const stringValues = serialized || [];
      return stringValues.map(value => ({
        value
      }));
    }

    serialize(deserialized) {
      const strings = deserialized || [];
      return strings.map(_ref => {
        let {
          value
        } = _ref;
        return value;
      });
    }

  }

  _exports.default = StringArrayTransform;
});
define("api/abilities/worker", ["exports", "api/abilities/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Provides abilities for workers.
   */
  class WorkerAbility extends _model.default {
    // =permissions

    /**
     * @type {boolean}
     */
    get canCreateWorkerLed() {
      return this.hasAuthorizedCollectionAction('create:worker-led');
    }

  }

  _exports.default = WorkerAbility;
});
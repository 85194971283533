define("api/transforms/object-as-array", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ObjectAsArrayTransform extends _transform.default {
    deserialize(serialized) {
      const obj = serialized || {};
      return Object.entries(obj).map(_ref => {
        let [key, value] = _ref;
        return {
          key,
          value
        };
      });
    }

    serialize(deserialized) {
      const array = deserialized || [];

      if (!array.length) {
        return null;
      }

      return array.reduce((result, currentValue) => {
        const {
          key,
          value
        } = currentValue;
        result[key] = value;
        return result;
      }, {});
    }

  }

  _exports.default = ObjectAsArrayTransform;
});
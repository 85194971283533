define("api/serializers/credential", ["exports", "@babel/runtime/helpers/esm/defineProperty", "api/serializers/application", "ember-copy"], function (_exports, _defineProperty2, _application, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CredentialSerializer extends _application.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "serializeScopeID", false);
    }

    serialize() {
      const serialized = super.serialize(...arguments);

      if (serialized.type === 'json') {
        delete serialized.attributes.username;

        if (serialized?.attributes?.json_object) {
          serialized.attributes.object = JSON.parse(serialized.attributes.json_object);
          delete serialized.attributes.json_object;
        }
      } else {
        delete serialized.attributes.json_object;
      }

      return serialized;
    }

    normalize(typeClass, hash) {
      const normalizedHash = (0, _emberCopy.copy)(hash, true);

      for (var _len = arguments.length, rest = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        rest[_key - 2] = arguments[_key];
      }

      const normalized = super.normalize(typeClass, normalizedHash, ...rest); // Remove secret fields as we don't track them after being created/updated

      normalized.data.attributes.password = '';
      normalized.data.attributes.private_key = '';
      normalized.data.attributes.private_key_passphrase = '';
      normalized.data.attributes.json_object = '';
      return normalized;
    }

  }

  _exports.default = CredentialSerializer;
});
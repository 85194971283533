define("api/transforms/principal-array", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PrincipalArrayTransform extends _transform.default {
    deserialize(serialized) {
      const stringValues = serialized || [];
      return stringValues.map(_ref => {
        let {
          id: principal_id,
          scope_id,
          type
        } = _ref;
        return {
          principal_id,
          scope_id,
          type
        };
      });
    }

    serialize(deserialized) {
      const strings = deserialized || [];
      return strings.map(_ref2 => {
        let {
          principal_id: id,
          scope_id,
          type
        } = _ref2;
        return {
          id,
          scope_id,
          type
        };
      });
    }

  }

  _exports.default = PrincipalArrayTransform;
});
define("api/models/credential", ["exports", "api/generated/models/credential"], function (_exports, _credential) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.types = void 0;

  /**
   * Supported Credential types.
   */
  const types = ['username_password', 'ssh_private_key', 'json'];
  _exports.types = types;

  class CredentialModel extends _credential.default {
    // =attributes

    /**
     * All Credentials are prefixed with "cred" and are considered
     * static due to their relation with Static Credential Stores.
     *
     * True if credential starts with the "cred" prefix.
     * @type {boolean}
     */
    get isStatic() {
      return this.id.startsWith('cred');
    }
    /**
     * True if type is `json`.
     * @type {boolean}
     */


    get isJSON() {
      return this.type === 'json';
    }
    /**
     * True if credential is an unknown type.
     * @type {boolean}
     */


    get isUnknown() {
      return !types.includes(this.type);
    }

  }

  _exports.default = CredentialModel;
});
define("api/serializers/credential-library", ["exports", "@babel/runtime/helpers/esm/defineProperty", "api/serializers/application", "api/models/credential-library"], function (_exports, _defineProperty2, _application, _credentialLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CredentialLibrarySerializer extends _application.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "serializeScopeID", false);
    }

    /**
     * @override
     * @method serialize
     * @param {Snapshot} snapshot
     */
    serialize() {
      const serialized = super.serialize(...arguments);

      if (serialized.attributes) {
        if (serialized.type !== _credentialLibrary.TYPE_CREDENTIAL_LIBRARY_VAULT_GENERIC) {
          delete serialized.attributes.http_method;
        } // Serialize `http_request_body` only if `http_method` is POST


        if (!serialized.attributes?.http_method?.match(/post/i)) delete serialized.attributes.http_request_body;
      }

      return serialized;
    }

  }

  _exports.default = CredentialLibrarySerializer;
});
define("api/abilities/host-set", ["exports", "api/abilities/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Provides abilities for host sets.
   */
  class HostSetAbility extends _model.default {
    // =permissions

    /**
     * @type {boolean}
     */
    get canAddHosts() {
      return this.hasAuthorizedAction('add-hosts');
    }
    /**
     * @type {boolean}
     */


    get canRemoveHosts() {
      return this.hasAuthorizedAction('remove-hosts');
    }
    /**
     * @type {boolean}
     */


    get canRead() {
      return !this.model.isUnknown && this.hasAuthorizedAction('read');
    }

  }

  _exports.default = HostSetAbility;
});
define("api/serializers/credential-store", ["exports", "api/serializers/application", "ember-copy"], function (_exports, _application, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CredentialStoreSerializer extends _application.default {
    /**
     * @override
     * @method serialize
     * @param {Snapshot} snapshot
     */
    serialize(snapshot) {
      switch (snapshot.record.type) {
        case 'vault':
          return this.serializeVault(...arguments);

        case 'static':
        default:
          return this.serializeStatic(...arguments);
      }
    }

    serializeVault() {
      const serialized = super.serialize(...arguments);

      if (serialized.attributes) {
        // Client certificate key cannot be unset when certificate is set.
        // If it's falsy, it must be removed.
        if (!serialized.attributes?.client_certificate_key && serialized.attributes?.client_certificate) delete serialized.attributes.client_certificate_key;
      }

      return serialized;
    }

    serializeStatic() {
      const serialized = super.serialize(...arguments); // Delete attributes for static cred store

      delete serialized.attributes;
      return serialized;
    }

    normalize(typeClass, hash) {
      const normalizedHash = (0, _emberCopy.copy)(hash, true);

      for (var _len = arguments.length, rest = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        rest[_key - 2] = arguments[_key];
      }

      const normalized = super.normalize(typeClass, normalizedHash, ...rest); // Remove secret fields as we don't track them after being created/updated

      normalized.data.attributes.token = '';
      return normalized;
    }

  }

  _exports.default = CredentialStoreSerializer;
});
define("api/abilities/managed-group", ["exports", "api/abilities/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Provides abilities for managed groups.
   */
  class ManagedGroupAbility extends _model.default {// no op
  }

  _exports.default = ManagedGroupAbility;
});
define("api/transforms/host-source-id-array", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class HostSourceIdArrayTransform extends _transform.default {
    deserialize(serialized) {
      const stringValues = serialized || [];
      return stringValues.map(_ref => {
        let {
          id: host_source_id,
          ...obj
        } = _ref;
        return {
          host_source_id,
          ...obj
        };
      });
    }

    serialize(deserialized) {
      const strings = deserialized || [];
      return strings.map(_ref2 => {
        let {
          host_source_id: id,
          ...obj
        } = _ref2;
        return {
          id,
          ...obj
        };
      });
    }

  }

  _exports.default = HostSourceIdArrayTransform;
});
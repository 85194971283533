define("api/transforms/account-claim-map-array", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AccountClaimMapArrayTransform extends _transform.default {
    deserialize(serialized) {
      const accountClaims = serialized || [];
      return accountClaims.map(accountClaimString => ({
        from: accountClaimString.split('=')[0],
        to: accountClaimString.split('=')[1]
      }));
    }

    serialize(deserialized) {
      const accountClaims = deserialized || [];
      return accountClaims.map(_ref => {
        let {
          from,
          to
        } = _ref;
        return `${from}=${to}`;
      });
    }

  }

  _exports.default = AccountClaimMapArrayTransform;
});